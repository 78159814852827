<template>
    <p class="text-sm text-gray-700">
        Mostrando
        <span class="font-medium">{{ meta.from }}</span>
        a
        <span class="font-medium">{{ meta.to }}</span>
        de
        <span class="font-medium">{{ meta.total }}</span>
        registros
    </p>
</template>

<script>
export default {
    name: "PaginateTextInfo",
    props: ['meta']
}
</script>

<style scoped>

</style>