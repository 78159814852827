<template>
    <div class="flex justify-center sm:justify-end w-full">
        <label class="flex flex-row justify-end items-center w-full">
            <input v-model="text" class="form-input text-center w-full max-w-md" placeholder="Buscar" ref="searchRef">
        </label>
    </div>
</template>

<script>

export default {
    jname: "Searcher",
    props: ['focusEnabled'],
    data() {
        return {
            text: null
        }
    },
    watch: {
        text(n) {
            this.$emit('changeText', n);
        }
    },
    mounted() {
        /*if (this.focusEnabled) {
            this.$refs.searchRef.focus();
        }*/
    }
}
</script>

<style scoped>

</style>