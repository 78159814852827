<template>
    <div class="pt-16 xl:pt-20 w-full h-screen flex flex-col justify-start items-center">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "SectionWrapper"
}
</script>

<style scoped>

</style>