<template>
    <nav v-if="meta" class="relative z-0 inline-flex shadow-sm -space-x-px" aria-label="Pagination">
        <span v-for="(el,index) in meta.links" :key="index" @click="changePage(el.url)">
            <a v-if="index === 0"
                class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-400
                bg-white text-sm font-medium text-gray-500 hover:bg-gray-400 hover:text-gray-700 h-10">
                <span class="sr-only">Previous</span>
                <i class="mdi mdi-chevron-left my-chevron"></i>
            </a>
            <a v-else-if="index === meta.links.length-1"
                class="cursor-pointer relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-400
                bg-white text-sm font-medium text-gray-500 hover:bg-gray-400 hover:text-gray-700 h-10">
                <span class="sr-only">Next</span>
                <i class="mdi mdi-chevron-right my-chevron"></i>
            </a>
            <a v-else :class="el.active ? 'bg-bo-red text-white' : 'bg-white text-gray-700'"
                class="cursor-pointer relative inline-flex items-center px-4 py-2 border border-gray-400 text-sm
                font-medium hover:bg-gray-400 hover:text-gray-700 h-10">
                {{ el.label }}
            </a>
        </span>
    </nav>
</template>

<script>
import {actions} from "@/store";
export default {
    name: "PaginateLinks",
    props: ['meta', 'selectable'],
    methods: {
        changePage(url){
            let pos = url.indexOf("page=");
            this.$emit('changePage', url.substring(pos + 5))
            if (this.selectable) {
                actions.setElSelected(false);
            }
        },
    }
}
</script>

<style scoped>
</style>