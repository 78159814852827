<template>
    <div class="flex flex-row justify-left items-center p-1 rounded rounded-sm">
        <div class="flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-16 sm:w-16">
            <i v-if="routeName === 'BackAreas'" class="mdi mdi-folder-multiple-outline text-4xl text-main"></i>
            <i v-if="routeName === 'BackAreaAmounts'" class="mdi mdi-folder-star-multiple-outline text-4xl text-main"></i>
            <i v-if="routeName === 'BackCourses'" class="mdi mdi-school-outline text-4xl text-main"></i>
            <i v-if="routeName === 'BackEditions'" class="mdi mdi-calendar-multiple-check text-4xl text-main"></i>
            <i v-if="routeName === 'BackUsers'" class="mdi mdi-account-group text-4xl text-main"></i>
            <i v-if="routeName === 'BackConnections'" class="mdi mdi-transit-connection-horizontal text-4xl text-main"></i>
            <i v-if="routeName === 'BackNotifications'" class="mdi mdi-cellphone-message text-4xl text-main"></i>
        </div>
        <span class="text-4xl font-semibold text-gray-700 ml-2">{{ title }}</span>
    </div>
</template>

<script>

export default {
    name: "Header",
    props: ['title'],
    computed: {
        routeName() {
            return this.$route.name;
        }
    },
}
</script>

<style scoped>

</style>