<template>
    <div class="px-2 py-1 flex flex-row items-center justify-between w-full">
        <button @click="changePage(links.prev)" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-chevron-left text-2xl font-semibold leading-none"></i>
        </button>
        <button @click="changePage(links.next)" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i class="mdi mdi-chevron-right text-2xl font-semibold leading-none"></i>
        </button>
    </div>
</template>

<script>
/*import {actions} from "@/store";*/

export default {
name: "PaginateMobile",
    props: ['links'],
    methods: {
        changePage(url){
            let pos = url.indexOf("page=");
            this.$emit('changePage', url.substring(pos + 5))
        }
    }
}
</script>

<style scoped>

</style>