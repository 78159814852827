<template>
    <div class="hidden sm:block sm:w-56 flex justify-center sm:justify-start">
        <label class="flex flex-row items-center">
            <span class="text-gray-700 mr-2">Mostrar</span>
            <select class="form-select w-16 px-1 pr-6 text-center" @change="onChange">
                <option v-for="(item) in options" :key="item">
                    {{ item }}
                </option>
            </select>
            <span class="text-gray-700 ml-2">registros</span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'PerPageSelector',
    components: {
    },
    data(){
        return {
            options: [10, 15, 20, 50, 100],
            selected: 10
        }
    },
    methods: {
        selection(option){
            this.selected = option;
            this.$emit('changeOption', option)
        },
        onChange(event) {
            this.selected = event.target.value;
            this.$emit('changeOption', this.selected)
        }
    }
}
</script>

<style scoped>
.form-selected option {
    color: #FFF;
    font-weight: bold;
}
</style>