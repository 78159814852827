<template>
    <div class="py-2 flex items-center justify-between">
        <div class="hidden sm:flex-1 sm:flex flex-col lg:flex-row sm:items-end sm:justify-between">
            <div>
                <PaginateTextInfo :meta="meta"/>
            </div>
            <div>
                <PaginateLinks :meta="meta" :selectable="true" @changePage="changePage"/>
            </div>
        </div>
    </div>
</template>

<script>

import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
import PaginateLinks from "@/pages/inside/shared/datatables/PaginateLinks";

export default {
    name: "Paginate",
    props: ['meta'],
    components: { PaginateTextInfo, PaginateLinks },
    methods: {
        changePage(page){
            this.$emit('changePage', page)
        }
    }
}
</script>

<style scoped>
    .my-chevron{
        font-size: 1.3rem;
        line-height: 1.3rem
    }
</style>