<template>
    <div class="relative w-full inline-flex text-xs font-medium cursor-pointer">
        <div v-on:mouseover="tooltip = true" v-on:mouseleave="tooltip = false" class="flex flex-row justify-end px-1 py-2 w-full">
            <p class="text-center truncate w-full text-xs uppercase">{{ fieldLabel }}</p>
            <div v-if="fieldBy === column" class="flex items-center justify-center w-6 mx-1">
                <i v-if="fieldBy === column && direction === 'desc'"
                   class="mdi mdi-chevron-down text-xl leading-none bg-gray-400 text-gray-700 rounded-sm shadow-md"></i>
                <i v-else-if="fieldBy === column && direction === 'asc'"
                   class="mdi mdi-chevron-up text-xl leading-none bg-gray-400 text-gray-700 rounded-sm shadow-md"></i>
<!--                <i v-else class="mdi mdi-unfold-more-horizontal text-xl leading-none rounded-sm shadow-md"></i>-->
            </div>
        </div>
        <div class="fixed" v-show="tooltip">
            <div class="absolute top-0 z-30 p-2 -mt-1 text-xs leading-tight text-gray-800 transform translate-x-4 -translate-y-full
                bg-gray-300 rounded-lg shadow-lg text-center font-normal capitalize" style="min-width: 8rem;">
                {{ fieldLabel }}
            </div>
            <svg class="absolute z-10 w-6 h-6 text-gray-300 transform translate-x-12 -translate-y-3 fill-current stroke-current" width="8" height="8">
                <rect x="12" y="-10" width="8" height="8" transform="rotate(45)" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: "CellHeader",
    props: ['column', 'fieldBy', 'direction', 'fieldLabel'],
    data() {
        return {
            tooltip: false
        }
    }
}
</script>

<style scoped>

</style>